export const colors = {
  green: {
    75: `#4DB165`,
  },
  gray: {
    75: `#F1F5F9`,
  },
  grad1: `linear-gradient(90deg, rgba(131,58,120,1) 0%, rgba(148,48,48,1) 50%, rgba(252,176,69,1) 100%)`,
  grad2: `radial-gradient(circle, rgba(16,14,16,1) 0%, rgba(250,106,17,1) 100%);`,
};
